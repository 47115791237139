import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class SubdomainService {
    private subdomain: string;

    constructor() {
        this.subdomain = this.extractSubdomain();
        console.log(this.subdomain);
    }

    /**
     * Extracts the subdomain from the current hostname.
     * For example, 'demo.mysite.com' returns 'demo'.
     */
    private extractSubdomain(): string {
        const hostname = window.location.hostname;
        const domainParts = hostname.split('.');

        if (domainParts.length > 2) {
            return domainParts.slice(0, -2).join('.');
        } else if (domainParts.length === 2) {
            return domainParts[0];
        } else {
            return '';
        }
    }

    /**
     * Returns the extracted subdomain.
     */
    getSubdomain(): string {
        return this.subdomain;
    }
}
