import { EnvironmentConfig } from '../app/core/environment-config';

export const environment: EnvironmentConfig = {
    name: 'dev',
    production: false,
    sentryDSN: null,
    buildTag: '',
    backend: 'https://aiconicsandbox.tech/api',
    auth0: 'https://dev-e8p2klvbdp7yqadn.eu.auth0.com/authorize?response_type=code&client_id=xiubxG4J2hoDEy0KB5rGgS0K21KOkGQF&redirect_uri=https://aiconicsandbox.tech/api/callback&scope=openid%20profile%20email',
};
