export interface UserRaw {
    email: string;
    display_name: string;
    modules: string[];
}

export interface IUser {
    email: string;
    displayName: string;
    modules: string[];
}

export class User implements IUser {
    email: string;
    displayName: string;
    modules: string[];

    static fromRaw(rawData: UserRaw): IUser {
        return {
            email: rawData.email,
            displayName: rawData.display_name,
            modules: rawData.modules,
        };
    }
}
