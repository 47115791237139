import { Injectable } from '@angular/core';
import { EnvironmentConfig } from '../environment-config';

@Injectable({
    providedIn: 'root',
})
export class ErrorReporter {
    constructor(private readonly env: EnvironmentConfig) {}

    reportError(err: any) {
        console.error(err.originalError || err);
    }
}
